/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var $window = $(window);

        // fluidvids
        fluidvids.init({
          selector: ['iframe', 'object'], // runs querySelectorAll()
          players: ['www.youtube.com', 'player.vimeo.com'] // players to support
        });

        $('.banner__scroll').on('click', function() {
          $('body').animate({
            scrollTop: ($window.height() * 0.65)
          });
        });

        // open terms
        $('.open-terms').on('click', function(e) {
          e.preventDefault();

          $('.terms').show();
        });

        // login menu
        $('.header__tool--highlight').on('click', ' > a', function(e) {
          e.preventDefault();

          $('.header__submenu').toggle();
        });

        // open menu
        $('.header__open-menu').on('click', function(e) {
          e.preventDefault();

          $('.header__menu').toggle();
        });

        // Scroll menu

        $('.header__menu-items').on('click', function(e) {
          var link = $(this).find('a'),
              href = link.attr('href');

          if ($(href).length > 0) {
            e.preventDefault();

            $('html, body').animate({
              scrollTop: $(href).offset().top,
            }, 800);
          } else {
            window.location = '/quero-conhecer/' + href;
          }

          return;
        });

        // newsletter
        $('.footer__newsletter-option').on('click', function(e) {
          var id = $('input[name=newsletter-option]:checked').val();

          $('#mc-embedded-subscribe-form').attr('action', '//punkssa.us14.list-manage.com/subscribe/post?u=bc115a8426fcef693c92d96d0&amp;id=' + id);
          $('.newsletter__hidden').attr('name', 'b_bc115a8426fcef693c92d96d0_' + id);

        });

        // Waypoints
        var sections = $('.section'),
            nav = $('.header__menu');

        sections.waypoint(function(direction) {
          if (direction === 'down') {
            var sectionId = this.element.id;

            if (sectionId) {
              nav.find('.active').removeClass('active');
              nav.find('a[href="#' + sectionId + '"]').addClass('active');
            }
          }
        }, {
          offset: '25%',
        });

        sections.waypoint(function(direction) {
          if (direction === 'up') {
            var sectionId = this.element.id;

            if (sectionId) {
              nav.find('.active').removeClass('active');
              nav.find('a[href="#' + sectionId + '"]').addClass('active');
            }
          }
        }, {
          offset: '-50%',
        });

        // Load more projects
        $('#load-projects').on('click', function(e) {
          e.preventDefault();

          var self = $(this),
              page = $(this).data('paged'),
              perPage = $(this).data('per-page'),
              total = perPage + 1,
              wrapper = $('.projects'),
              i, projects, image;

          $.ajax({
              url : '/wp-json/wp/v2/portfolio',
              data : {
                _embed: 'featured_image',
                per_page: perPage,
                page: page
              },
              dataType : 'json',
              success : function(data, textStatus, request) {
                if (request.getResponseHeader('X-WP-TotalPages') != page) {
                  self.data('paged', page + 1);
                } else {
                  self.hide();
                }

                for (i = 0; i < total; i++) {
                  if (data[i]) {
                    image = data[i]['_embedded']['wp:featuredmedia'];
                    projects = '<div class="projects__item" data-project-id="' + data[i]['id'] + '" style="background-image: url(' + image[0]['media_details']['sizes']['full']['source_url'] + ')">';
                    projects += '<div class="projects__item-content"><h3 class="projects__item-title">' + data[i]['title']['rendered'] + '</h3><div class="projects__item-body">' + data[i]['excerpt']['rendered'] + '</div></div></div>';

                    wrapper.append(projects);
                  }
                }
              },
              error : function() {
                  alert('Não foi possível carregar novas trabalhos.');
              }
            });
        });

        // load more news
        $('#load-news').on('click', function(e) {
          e.preventDefault();

          var self = $(this),
              page = $(this).data('paged'),
              perPage = $(this).data('per-page'),
              total = perPage + 1,
              wrapper = $('.news'),
              i, news, image;

          $.ajax({
            url : '/wp-json/wp/v2/posts',
            data : {
              _embed: 'featured_image',
              per_page: perPage,
              page: page
            },
            dataType : 'json',
            success : function(data, textStatus, request) {
                if (request.getResponseHeader('X-WP-TotalPages') != page) {
                  self.data('paged', page + 1);
                } else {
                  self.hide();
                }

                for (i = 0; i < total; i++) {
                  if (data[i]) {
                    news = '<div class="news__item" data-post-id="' + data[i]['id'] + '">';

                    image = data[i]['_embedded']['wp:featuredmedia'];

                    if (image.length > 0) {
                      news += '<img class="wp-post-image news__item-image" src="' + image[0]['media_details']['sizes']['full']['source_url'] + '" alt="' + image[0]['caption']['rendered'] + '">';
                    }

                    news += '<div class="news__item-content"><div class="news__item-body">' + data[i]['title']['rendered'] + data[i]['excerpt']['rendered'] + '</div></div></div>';

                    wrapper.append(news);
                  }
                }
            },
            error : function() {
                alert('Não foi possível carregar novas notícias.');
            }
          });
        });

        // Open project lightbox
        $('.projects').on('click', '.projects__item', function(e) {
          e.preventDefault();

          var id = $(this).data('project-id');

          $.ajax({
              url : '/wp-json/wp/v2/portfolio/' + id,
              dataType: 'json',
              success : function(data){
                populateLightbox(data, 'lightbox__box--project')
              }
          });
        });

        // Open post lightbox
        $('.news').on('click', '.news__item', function(e) {
          e.preventDefault();

          var id = $(this).data('post-id');

          $.ajax({
              url : '/wp-json/wp/v2/posts/' + id,
              dataType: 'json',
              success : function(data) {
                  populateLightbox(data, 'lightbox__box--post')
              }
          });
        });

        $(document).on('click', '.lightbox__close', function(e) {
          e.preventDefault();

          $('.lightbox').fadeOut();
          $('body').css('overflow-y', 'auto');
          window.history.go(-1);
        });

        // add placeholder to quiz input - not doable via admin
        var placeholder = $('.wpcf7-quiz-label').text();
        $('.wpcf7-quiz').attr('placeholder', placeholder);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Map
function initMap() {
  var styledMapType = new google.maps.StyledMapType(
  [
  {
  "featureType": "administrative",
  "elementType": "labels.text.fill",
  "stylers": [
  {
  "color": "#444444"
  }
  ]
  },
  {
  "featureType": "administrative.locality",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "visibility": "simplified"
  },
  {
  "hue": "#ff0042"
  }
  ]
  },
  {
  "featureType": "administrative.neighborhood",
  "elementType": "all",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "administrative.neighborhood",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "administrative.land_parcel",
  "elementType": "all",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "administrative.land_parcel",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.fill",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "administrative.land_parcel",
  "elementType": "labels.text.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "landscape",
  "elementType": "all",
  "stylers": [
  {
  "color": "#ffffff"
  },
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "landscape",
  "elementType": "geometry.fill",
  "stylers": [
  {
  "visibility": "on"
  },
  {
  "color": "#ffffff"
  }
  ]
  },
  {
  "featureType": "landscape.man_made",
  "elementType": "labels.text.fill",
  "stylers": [
  {
  "visibility": "off"
  },
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "landscape.natural.landcover",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "landscape.natural.terrain",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "poi",
  "elementType": "all",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "poi.attraction",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "visibility": "off",
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "road",
  "elementType": "all",
  "stylers": [
  {
  "visibility": "off",
  "saturation": -100
  },
  {
  "lightness": 45
  }
  ]
  },
  {
  "featureType": "road",
  "elementType": "geometry.fill",
  "stylers": [
  {
  "visibility": "on"
  },
  {
  "color": "#222"
  },
  {
  "weight": "0.50"
  }
  ]
  },
  {
  "featureType": "road",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  },
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "road",
  "elementType": "labels.text.fill",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "road",
  "elementType": "labels.text.stroke",
  "stylers": [
  {
  "color": "#ffffff"
  }
  ]
  },
  {
  "featureType": "road.highway",
  "elementType": "all",
  "stylers": [
  {
  "visibility": "simplified"
  }
  ]
  },
  {
  "featureType": "road.highway",
  "elementType": "geometry.fill",
  "stylers": [
  {
  "visibility": "off"
  },
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "road.highway",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  },
  {
  "visibility": "on"
  },
  {
  "weight": ".01"
  }
  ]
  },
  {
  "featureType": "road.highway.controlled_access",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "road.arterial",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "road.arterial",
  "elementType": "labels.icon",
  "stylers": [
  {
  "visibility": "off"
  },
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "road.local",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "transit",
  "elementType": "all",
  "stylers": [
  {
  "visibility": "off"
  }
  ]
  },
  {
  "featureType": "transit.line",
  "elementType": "geometry.stroke",
  "stylers": [
  {
  "visibility": "simplified"
  },
  {
  "color": "#222"
  }
  ]
  },
  {
  "featureType": "water",
  "elementType": "all",
  "stylers": [
  {
  "color": "#222"
  },
  {
  "visibility": "on"
  }
  ]
  },
  {
  "featureType": "water",
  "elementType": "geometry.fill",
  "stylers": [
  {
  "visibility": "on"
  },
  {
  "color": "#222222"
  },
  {
  "weight": "1.00"
  }
  ]
  },
  {
  "featureType": "water",
  "elementType": "labels.text",
  "stylers": [
  {
  "weight": "0.20"
  }
  ]
  }
  ],
  {name: 'Styled Map'});

  if (jQuery('#map').length > 0) {
      // Create a map object, and include the MapTypeId to add
      // to the map type control.
      var map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: -38.8631, lng: -57.5294},
        zoom: 4,
        minZoom: 3,
        mapTypeControlOptions: {
          mapTypeIds: []
        }
      });

      //Associate the styled map with the MapTypeId and set it to display.
      map.mapTypes.set('styled_map', styledMapType);
      map.setMapTypeId('styled_map');

      // Markers
      var markerIcon = '/wp-content/themes/punks2017/dist/images/pointer.png';

      var markerSP = new google.maps.Marker({
        position: {lat: -23.5532, lng: -46.6974},
        map: map,
        icon: markerIcon,
      });

      var markerCh = new google.maps.Marker({
        position: {lat: -33.4529, lng: -70.6714},
        map: map,
        icon: markerIcon,
      });
    }
  }

function populateLightbox(data, newClass) {
    jQuery('body').css('overflow-y', 'hidden');
    jQuery('.lightbox__body').html(data.content.rendered);
    jQuery('.lightbox__title').html(data.title.rendered);
    jQuery('.lightbox__box').attr('class', 'lightbox__box ' + newClass);
    fluidvids.render();
    jQuery('.lightbox').fadeIn();

    window.history.pushState({ id: data.id }, data.title.rendered, data.link);
}

function findGetParameter(parameterName) {
  var result = null,
    tmp = [];

  location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
}
